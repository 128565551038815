import React from 'react'
import { withRouter } from 'react-router-dom'
import { useVersion } from '../../contexts/Application'
import { useState } from 'react'
import { CHAINS, CHAINS_LOGO } from '../../constants'
import styled from 'styled-components'
import { ChevronDown } from 'react-feather'
import { TYPE } from '../../Theme'
import Row, { RowBetween } from '../Row'
import { StyledIcon } from '..'
import { AutoColumn } from '../Column'
import { BasicLink } from '../Link'

const Wrapper = styled.div`
  z-index: 20;
  position: relative;
  background-color: ${({ theme }) => theme.panelColor};
  border: 1px solid ${({ open, color }) => (open ? color : '#434564')} 
  width: 170px;
  padding: 4px 10px;
  padding-right: 6px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`

const Dropdown = styled.div`
  z-index: 999999;
  position: absolute;
  top: 40px;
  padding-top: 40px;
  width: calc(100% - 40px);
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid #434564;
  padding: 10px 10px;
  border-radius: 8px;
  width: calc(100% - 20px);
  font-weight: 500;
  font-size: 1rem;
  color: black;
  :hover {
    cursor: pointer;
  }
`

const ArrowStyled = styled(ChevronDown)`
  height: 20px;
  width: 20px;
  margin-left: 6px;
  transform: ${({ open }) => open && 'rotate(180deg)'};
  transition-duration: 0.3s;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  margin-right: 1rem;
`

const NetworkRow = styled(BasicLink)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  :hover {
    background-color: #393939;
  }
`


function NetworkSwitcher() {
  const [showDropdown, toggleDropdown] = useState(false)

  const { isV3, networkLabel } = useVersion()

  const [network, setNetwork] = useState(networkLabel)

  return (
    <Wrapper
      open={showDropdown}
      color={'#f58b00'}
      tabIndex="0"
      onBlur={(e) => !e.currentTarget.contains(e.relatedTarget) && toggleDropdown(false)}
    >
      <RowBetween onClick={() => toggleDropdown(!showDropdown)} justify="center" style={{ 'padding-top': '3px', 'padding-bottom': '3px' }}>
        <Row>
          <Image src={CHAINS_LOGO[network]} size={'20px'} />
          <TYPE.main style={{ textTransform: 'capitalize', userSelect: 'none' }}>{network}</TYPE.main>
        </Row>
        <StyledIcon>
          <ArrowStyled open={showDropdown} />
        </StyledIcon>
      </RowBetween>
      {showDropdown && (
        <Dropdown>
          <AutoColumn gap="5px">
            {Object.keys(CHAINS).map((key, index) => {
              const option = CHAINS[key]
              return (
                option !== network && (
                  <NetworkRow
                    onClick={() => {
                      toggleDropdown(!showDropdown)
                      setNetwork(option)
                      setTimeout(() => window.location.reload(), 500)
                    }}
                    key={index}
                    to={`/home/${option}/${isV3 ? 'v3' : 'v2'}`}
                  >
                    <Image src={CHAINS_LOGO[option]} size={'20px'} />
                    <TYPE.body fontSize={14} style={{ textTransform: 'capitalize' }}>
                      {option}
                    </TYPE.body>
                  </NetworkRow>
                )
              )
            })}
          </AutoColumn>
        </Dropdown>
      )}
    </Wrapper>
  )
}

export default withRouter(NetworkSwitcher)
