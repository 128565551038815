import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { CHAINS } from '../constants'

const getClient = (uri) =>
  new ApolloClient({
    link: new HttpLink({ uri }),
    cache: new InMemoryCache(),
    shouldBatch: true,
  })

export const clientsV2 = {
  [CHAINS.ARBITRUM]: getClient(
    'https://gateway.thegraph.com/api/44e29639aebbb35310b5c9bf9c8ae679/deployments/id/QmadksPPom4tzys3utLDNf699dQNYVEhTBAMVFVyypZF7P'
  ),
  [CHAINS.GRAVITY]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv2-gravity/version/v1.0.0/api'
  ),
  [CHAINS.MOLTEN]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv2-molten/version/v1.0.0/api'
  ),
  [CHAINS.PROOF_OF_PLAY]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv2-proof-of-play/version/v1.0.0/api'
  ),
  [CHAINS.RARI]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv2-rari/version/v1.0.0/api'
  ),
  [CHAINS.REYA]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv2-reya/version/v1.0.0/api'
  ),
  [CHAINS.SANKO]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv2-sanko/version/v1.0.0/api'
  ),
  [CHAINS.WINR]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv2-winr/version/v1.0.0/api'
  ),
  [CHAINS.XAI]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv2-xai/version/v1.0.0/api'
  ),
}

export const clientsV3 = {
  [CHAINS.ARBITRUM]: getClient(
    'https://gateway.thegraph.com/api/44e29639aebbb35310b5c9bf9c8ae679/deployments/id/QmVPCT62C6b2m2D3AnfEF1hJhhmYEenuQtUDLMj1vEBt4m'
  ),
  [CHAINS.GRAVITY]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv3-gravity/version/v1.0.0/api'
  ),
  [CHAINS.MOLTEN]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv3-molten/version/v1.0.0/api'
  ),
  [CHAINS.PROOF_OF_PLAY]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv3-proof-of-play/version/v1.0.0/api'
  ),
  [CHAINS.RARI]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv3-rari/version/v1.0.0/api'
  ),
  [CHAINS.REYA]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv3-reya/version/v1.0.0/api'
  ),
  [CHAINS.SANKO]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv3-sanko/version/v1.0.0/api'
  ),
  [CHAINS.WINR]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv3-winr/version/v1.0.0/api'
  ),
  [CHAINS.XAI]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/camelot-ammv3-xai/version/v1.0.0/api'
  ),
}

export const blockClients = {
  [CHAINS.ARBITRUM]: getClient(
    'https://gateway-arbitrum.network.thegraph.com/api/44e29639aebbb35310b5c9bf9c8ae679/subgraphs/id/JBnWrv9pvBvSi2pUZzba3VweGBTde6s44QvsDABP47Gt'
  ),
  [CHAINS.GRAVITY]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/gravity-blocks/version/v0.1.0/api'
  ),
  [CHAINS.MOLTEN]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/molten-blocks/version/v0.1.0/api'
  ),
  [CHAINS.PROOF_OF_PLAY]: getClient(
    'https://subgraph.satsuma-prod.com/42b742afce37/camelot/proof-of-play-blocks/version/v0.1.0/api'
  ),
  [CHAINS.RARI]: getClient(
    'https://api.goldsky.com/api/public/project_cld6kdj9u539w0htd50sbeqyz/subgraphs/rari-blocks/1/gn'
  ),
  [CHAINS.REYA]: getClient('https://subgraph.satsuma-prod.com/42b742afce37/camelot/reya-blocks/version/v0.1.0/api'),
  [CHAINS.SANKO]: getClient('https://subgraph.satsuma-prod.com/42b742afce37/camelot/sanko-blocks/version/v0.1.0/api'),
  [CHAINS.WINR]: getClient('https://subgraph.satsuma-prod.com/42b742afce37/camelot/winr-blocks/version/v0.1.0/api'),
  [CHAINS.XAI]: getClient(
    'https://api.goldsky.com/api/public/project_cl8ylkiw00krx0hvza0qw17vn/subgraphs/blocks/xai/gn'
  ),
}

export const healthClient = getClient('https://api.thegraph.com/index-node/graphql')
