import ArbitrumLogo from '../assets/arbitrum.png'
import GravityLogo from '../assets/gravity.png'
import MoltenLogo from '../assets/molten.png'
import ProofOfPlayLogo from '../assets/proof-of-play.png'
import RariLogo from '../assets/rari.svg'
import ReyaLogo from '../assets/reya.jpg'
import SankoLogo from '../assets/sanko.png'
import WinrLogo from '../assets/winr.png'
import XaiLogo from '../assets/xai.png'

export const CHAINS = {
  ARBITRUM: 'arbitrum',
  GRAVITY: 'gravity',
  MOLTEN: 'molten',
  RARI: 'rari',
  REYA: 'reya',
  PROOF_OF_PLAY: "proofofplay",
  SANKO: 'sanko',
  WINR: 'winr',
  XAI: 'XAI',
}

export const FACTORY_ADDRESSES = {
  [CHAINS.ARBITRUM]: '0x6EcCab422D763aC031210895C81787E87B43A652',
  [CHAINS.GRAVITY]: '0x7d8c6B58BA2d40FC6E34C25f9A488067Fe0D2dB4',
  [CHAINS.MOLTEN]: '0x7d8c6B58BA2d40FC6E34C25f9A488067Fe0D2dB4',
  [CHAINS.PROOF_OF_PLAY]: '0x7d8c6B58BA2d40FC6E34C25f9A488067Fe0D2dB4',
  [CHAINS.RARI]: '0x7d8c6B58BA2d40FC6E34C25f9A488067Fe0D2dB4',
  [CHAINS.REYA]: '0x7d8c6B58BA2d40FC6E34C25f9A488067Fe0D2dB4',
  [CHAINS.SANKO]: '0x7d8c6B58BA2d40FC6E34C25f9A488067Fe0D2dB4',
  [CHAINS.WINR]: '0x7d8c6B58BA2d40FC6E34C25f9A488067Fe0D2dB4',
  [CHAINS.XAI]: '0x18E621B64d7808c3C47bccbbD7485d23F257D26f',
}

export const FACTORY_ADDRESSES_V3 = {
  [CHAINS.ARBITRUM]: '0x1a3c9B1d2F0529D97f2afC5136Cc23e58f1FD35B',
  [CHAINS.GRAVITY]: '0x10aA510d94E094Bd643677bd2964c3EE085Daffc',
  [CHAINS.MOLTEN]: '0x10aA510d94E094Bd643677bd2964c3EE085Daffc',
  [CHAINS.PROOF_OF_PLAY]: '0x10aA510d94E094Bd643677bd2964c3EE085Daffc',
  [CHAINS.RARI]: '0xcF8d0723e69c6215523253a190eB9Bc3f68E0FFa',
  [CHAINS.REYA]: '0x10aA510d94E094Bd643677bd2964c3EE085Daffc',
  [CHAINS.SANKO]: '0xcF8d0723e69c6215523253a190eB9Bc3f68E0FFa',
  [CHAINS.WINR]: '0x10aA510d94E094Bd643677bd2964c3EE085Daffc',
  [CHAINS.XAI]: '0xD8676fBdfa5b56BB2298D452c9768f51e80e34AE',
}

export const WETH = {
  [CHAINS.ARBITRUM]: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
  [CHAINS.GRAVITY]: '0xBB859E225ac8Fb6BE1C7e38D87b767e95Fef0EbD',
  [CHAINS.MOLTEN]: '0xdBc38Db4F11b7D111477f17e757aa17dD3348a6d',
  [CHAINS.PROOF_OF_PLAY]: '0x77684A04145a5924eFCE0D92A7c4a2A2E8C359de',
  [CHAINS.RARI]: '0xf037540e51D71b2D2B1120e8432bA49F29EDFBD0',
  [CHAINS.REYA]: '0x6B48C2e6A32077ec17e8Ba0d98fFc676dfab1A30',
  [CHAINS.SANKO]: '0x754cDAd6f5821077d6915004Be2cE05f93d176f8',
  [CHAINS.WINR]: '0xBF6FA9d2BF9f681E7b6521b49Cf8ecCF9ad8d31d',
  [CHAINS.XAI]: '0x3fB787101DC6Be47cfe18aeEe15404dcC842e6AF',
}

export const CHAINS_LOGO = {
  [CHAINS.ARBITRUM]: ArbitrumLogo,
  [CHAINS.GRAVITY]: GravityLogo,
  [CHAINS.MOLTEN]: MoltenLogo,
  [CHAINS.PROOF_OF_PLAY]: ProofOfPlayLogo,
  [CHAINS.RARI]: RariLogo,
  [CHAINS.REYA]: ReyaLogo,
  [CHAINS.SANKO]: SankoLogo,
  [CHAINS.WINR]: WinrLogo,
  [CHAINS.XAI]: XaiLogo,
}

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  THREE_MONTHS: '3 months',
  YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = ['https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json']
export const TOKEN_LIST = 'https://token-list.camelot.exchange/tokens.json'

// hide from overview list
export const TOKEN_BLACKLIST = [
  '0xdea0102f2d45a944a720a1cdef87ec8366ea04f5',
  '0x3c393ed453c1017315d4401f6097ea4b944c06ac',
  '0x6e0aa9718c56ef5d19ccf57955284c7cd95737be',
  '0x4c89b40ea5408786e158b60c2711c8d28e7ad32d',
  '0xe4ef5a727ef598722bddc8c6ec33a31258d6a42f',
  '0xfd3b077dc97915ab3a49db1b1021c13ad55751dc',
  '0x0bbf5349d884d135a907040187e0f4e04d05129c',
  '0x3d5f8fb7e5de6bf2c1944d24c94ef48b84ad13c6',
  '0x556ae63bfb80c608589a5c78b456b2fa9145a0a3',
  '0xf3a61c7d53442ac00a3a99761c5839f9109f2193',
  '0x7df4eaa13fba3aa7ec3b77b412566e5204e1539a',
  '0x0fd72ba4e27ba45da0a2a1b45b395a2994751e96',
  '0xaa348cde30eca12ac31d21c3007f06d4c8e577ce',
  '0xd7d5d35883d46d77592d3bff7f1d2414eae01602',
  '0x97444f7a914c0ec57094d81a2f3ec5100691898a',
]

// pair blacklist
export const PAIR_BLACKLIST = [
  '0x8c1c4da6754cfbc37e09620ef2774fe59e7bba88',
  '0xce79b02ea60e97674f355ef47b250f20f8554878',
]

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = []

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or ETH pairings.'

// tokens that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES = [
  '0x26519b547416e4f53f3a4b05b95ef859c3bd89fe',
  '0xf6c7197eaff13002a7b2a2e835a7bb6969a4b026',
  '0x46622913ce40c54ec14857f72968d4baaf963947',
  '0x0f8d6953f58c0dd38077495aca64cbd1c76b7501',
]
